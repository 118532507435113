<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "FourEssentialStepsToDevelopingYourWritingPractice",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="FourEssentialStepsToDevelopingYourWritingPractice">
      <SuawParagraph
        text="One of the more difficult aspects of writing is carving out a regular time to write. It’s not enough to “free up time” or “squeeze in a few minutes here and there.” One of the reasons Shut Up & Write! is so successful is that we make an hour a week the minimum commitment to develop your writing practice."
      />
      <SuawHeading level="3" content="Writing consistently is the key to finishing your draft." />
      <SuawParagraph
        text="Discipline provides the foundation for establishing a regular writing practice. But since discipline is developed through repetition, organization provides the foundation for discipline. One hour per week is an absolute minimum, but more than an hour is obviously better. There are 168 hours in a week — do you think you can dedicate three of them to writing? How about five?"
      />
      <SuawParagraph text="If you average 1,000 words per hour, one hour of writing a week will be enough for a 52,000 word rough draft at the end of a year!" />
      <SuawParagraph text="Here are four essential steps you’ll need to develop your writing practice." />
      <SuawHeading level="3" content="Step 1" />
      <SuawParagraph
        text="Set a realistic goal for your writing project, and break it into milestones. Some milestones we’d suggest include: project synopsis, plot outline, character outline, first draft, or a certain number of chapters. Be specific when fleshing out your milestones. By focusing on milestones, you can make even the largest projects manageable."
      />
      <SuawHeading level="3" content="Step 2" />
      <SuawParagraph
        text="Once you have your milestones, create a schedule. Set a date for the completion of your project and then plan backwards from that date. Continue to ask yourself, “What needs to happen to complete that milestone?” The answer should always be the previous milestone."
      />
      <SuawParagraph
        text="We all know that life doesn’t always pan out quite the way we plan, so prepare for interruptions — for example, birthdays, holidays, and work deadlines. You can’t plan for unforeseen distractions, but you can plan around what you already have on your calendar."
      />
      <SuawParagraph
        text="When life happens and you do get pulled away from your writing, shake it off and try to pick back up where you left off. An “all or nothing” attitude doesn’t help in the long run. It’s much better to focus on the writing you are able to accomplish, instead of the time you might be wasting. So many goals are never achieved because they are abandoned after only a few mistakes — New Year’s resolutions are a perfect example."
      />
      <SuawHeading level="3" content="Step 3" />
      <SuawParagraph
        text="Set up your writing space. In this step, comfort is king. Identify a writing space where you’ll be comfortable for long stretches of time. Ideally, it should be conveniently located, quiet, and free of any family or work obligations. For more about this topic, check out our article on Creating Your Writing Environment."
      />
      <SuawHeading level="3" content="Step 4" />
      <SuawParagraph
        text="Assemble your tools! Make sure you have your writing implements: sharpened pencils, extra pens, plenty of paper, laptop fully charged. Prep your writing space so it’s cleared of clutter and distractions. Get any food or drink you might want for your writing session. Maybe grab some tea, coffee, beer, or wine? Then, consider setting your phone on Airplane Mode to cut down on distractions from emails and social media. This is your time that you’ve set aside, so make it just about you and your writing."
      />
      <SuawParagraph
        text="Getting your writing done can feel like a nebulous or intimidating process at times, but it’s really a matter of creating simple rituals and good habits. Breaking the process down into a few key steps will help you commit to your practice and hit your long-term writing goals."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
